import { getCampaign } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const purchaseLinksUpdated = response => ({
  type: types.PURCHASE_LINK_PARAMS_UPDATED,
  response
});

const setSubscriptionParams = data => dispatch => {
  const fulfilled = response => dispatch(purchaseLinksUpdated(response));
  return fulfilled(data);
};

const campaignLoaded = response => ({
  type: types.CAMPAIGN_DATA_LOADED,
  response
});

const campaignRejected = () => ({
  type: types.CAMPAIGN_DATA_REJECTED
});

const getRemoteCampaign = () => dispatch => {
  const fulfilled = response => dispatch(campaignLoaded(response));
  const rejected = error => {
    dispatch(campaignRejected());
    dispatch(errorActions.errorHandler(error));
  };

  return getCampaign(fulfilled, rejected);
};

const setActiveCampaign = data => dispatch => {
  const fulfilled = response => dispatch(campaignLoaded(response));
  return fulfilled(data);
};

const rejectCampaign = () => dispatch => dispatch(campaignRejected());

export default {
  setSubscriptionParams,
  getRemoteCampaign,
  setActiveCampaign,
  rejectCampaign
};
