import types from './types';
import initialState from './initialState';
import config from '../../config';

const {
  schibstedAccount: { afterPurchaseRedirectUri }
} = config;

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PURCHASE_LINK_PARAMS_UPDATED: {
      const buySubscription = {
        ...state.buySubscription,
        boxes: state.buySubscription.boxes.map(item => {
          const newURL = new URL(item.url);
          const newURLParams = newURL.searchParams;
          Object.entries(action.response).forEach(([k, v]) =>
            newURLParams.set(k, v)
          );
          // attach tag param only to after purchase redirect url
          if (newURLParams.has('tag')) newURLParams.delete('tag');

          const afterPruchaseURL = new URL(afterPurchaseRedirectUri);
          const afterPruchaseURLParams = afterPruchaseURL.searchParams;
          afterPruchaseURLParams.set('purchase', 1);
          afterPruchaseURLParams.set('code', newURLParams.get('code'));
          afterPruchaseURLParams.set('productId', newURLParams.get('code'));
          Object.entries(action.response)
            .filter(([k]) => !k.includes('utm_'))
            .forEach(([k, v]) => afterPruchaseURLParams.set(k, v));
          afterPruchaseURL.search = afterPruchaseURLParams.toString();
          const stateParam = {
            redirectUrl: afterPruchaseURL.toString()
          };
          newURLParams.set('state', btoa(JSON.stringify(stateParam)));

          newURL.search = newURLParams.toString();
          return { ...item, url: newURL.toString() };
        })
      };
      return {
        ...state,
        buySubscription
      };
    }
    case types.CAMPAIGN_DATA_LOADED: {
      return {
        ...state,
        heroBanner: action.response.heroBanner,
        footerAd: action.response.footerAd,
        salesWidget: action.response.salesWidget,
        buySubscription: action.response.buySubscription,
        campaignIsActive: action.response.campaignIsActive,
        campaignName: action.response.campaignName,
        campaignStartDate: action.response.campaignStartDate,
        campaignEndDate: action.response.campaignEndDate,
        forceRedesign: action.response.forceRedesign
      };
    }
    case types.CAMPAIGN_DATA_REJECTED: {
      return {
        ...state,
        pending: false
      };
    }
    default:
      return state;
  }
};

export default configReducer;
