import {
  ExperimentIdentifiers,
  getSalesposter
} from '@src/utils/helpers/experiments';

import types from './types';

const applySalesposters = (fulfilled, wsCalls) => {
  const getSalespostersPromise = getSalesposter(
    ExperimentIdentifiers.ArticlesListWidget,
    '',
    wsCalls,
    ExperimentIdentifiers.ArticlesListWidget
  );
  getSalespostersPromise.then(salesposter => {
    fulfilled([salesposter]);
  });
};

const articleSectionsFulfilled = (
  response,
  articleSearchPage,
  id,
  salesposters
) => ({
  type: types.CREATE_ARTICLE_SECTION_FULFILLED,
  response,
  articleSearchPage,
  salesposters,
  id
});

const articleSectionsRejected = error => ({
  type: types.CREATE_ARTICLE_SECTION_REJECTED,
  error: {
    message: error.message
  }
});

const articleSectionsPending = () => ({
  type: types.CREATE_ARTICLE_SECTION_PENDING
});

function getSalesArticlesList(
  id,
  articleSearchPage,
  articlesNumber = 15,
  tags,
  searchParamPage
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      applySalesposters(salesposters => {
        dispatch(
          articleSectionsFulfilled(
            response,
            searchParamPage || articleSearchPage,
            id,
            salesposters
          )
        );
      }, wsCalls);
    };

    const rejected = error => dispatch(articleSectionsRejected(error));
    const size = articlesNumber ? `?size=${articlesNumber}` : '';
    const searchPage = articleSearchPage ? `&pageId=${articleSearchPage}` : '';
    const tagsFilter = tags ? `&tags=${tags}` : '';

    const params = `${size}${searchPage}${tagsFilter}`;

    dispatch(articleSectionsPending());

    return wsCalls().getSalesArticlesList(fulfilled, rejected, id, params);
  };
}
function getPaywallArticlesList(
  id,
  articleSearchPage,
  articlesNumber = 15,
  tags,
  searchParamPage
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(
        articleSectionsFulfilled(
          response,
          searchParamPage || articleSearchPage,
          id
        )
      );
    };

    const rejected = error => dispatch(articleSectionsRejected(error));
    const size = articlesNumber ? `?size=${articlesNumber}` : '';
    const searchPage = articleSearchPage ? `&pageId=${articleSearchPage}` : '';
    const tagsFilter = tags ? `&tags=${tags}` : '';

    const params = `${size}${searchPage}${tagsFilter}`;

    dispatch(articleSectionsPending());

    return wsCalls().getPaywallArticlesList(fulfilled, rejected, id, params);
  };
}

const clearArticleSections = () => ({ type: types.CLEAR_ARTICLE_SECTIONS });

export default {
  getSalesArticlesList,
  getPaywallArticlesList,
  clearArticleSections
};
