import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import { getActiveCampaign } from './utils';
import config from '../../config';

import vektklubbCampaign from './vektklubb';
import wellobeCampaign from './wellobe';

const getConfig = () => {
  const baseConfig = isWellobe(config.product)
    ? wellobeCampaign
    : vektklubbCampaign;

  const { additionalOfferCampaign, campaignsConfig } = baseConfig.getAssets();

  const activeConfig = getActiveCampaign(campaignsConfig);

  activeConfig.buySubscription.boxes.forEach((_box, index) => {
    activeConfig.buySubscription.boxes[index].additionalOfferImage =
      additionalOfferCampaign;
  });

  return baseConfig.updateEnvVariables(activeConfig);
};

export default getConfig();
