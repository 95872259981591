import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const calorieLevelsByPaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CALORIE_LEVELS_BY_PACE_PENDING: {
      return { ...state, pending: true };
    }
    case types.GET_CALORIE_LEVELS_BY_PACE_FULFILLED: {
      return {
        ...state,
        pending: false,
        levels: action.response && action.response.levels,
        recommendedInitialPace:
          action.response && action.response.recommendedInitialPace
      };
    }
    case types.GET_CALORIE_LEVELS_BY_PACE_REJECTED: {
      return { ...state, pending: false };
    }
    case types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_PENDING: {
      return { ...state, pending: true };
    }
    case types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_FULFILLED: {
      return {
        ...state,
        pending: false,
        partialGoalLevels: action.response && action.response.levels
      };
    }
    case types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_REJECTED: {
      return { ...state, pending: false };
    }
    case types.POST_CALORIE_LEVELS_BY_PACE_PENDING: {
      return { ...state, postPending: true };
    }
    case types.POST_CALORIE_LEVELS_BY_PACE_FULFILLED: {
      return {
        ...state,
        postPending: false
      };
    }
    case types.POST_CALORIE_LEVELS_BY_PACE_REJECTED: {
      return { ...state, postPending: false };
    }
    case types.SET_CURRENT_PACE_INDEX: {
      return { ...state, currentPaceIndex: action.value };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.EDIT_KCAL_MODAL_ID) {
        return { ...state, currentPaceIndex: initialState.currentPaceIndex };
      }
      return state;
    }
    default:
      return state;
  }
};

export default calorieLevelsByPaceReducer;
