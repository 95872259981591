import { getUserId } from '@src/utils/helpers/authentication';
import {
  getCalorieLevelsByPace as getCalorieLevelsByPaceFromApi,
  getCalorieLevelsByPaceNoProgram as getCalorieLevelsByPaceNoProgramFromApi,
  postCalorieLevelsByPace as postCalorieLevelsByPaceToApi
} from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import { weightplanActions } from '@ducks/weightplan';
import { partialGoalActions } from '@ducks/partialGoal';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';

const getCalorieLevelsByPacePending = () => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_PENDING
});

const getCalorieLevelsByPaceFulfilled = response => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_FULFILLED,
  response
});

const getCalorieLevelsByPaceRejected = () => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_REJECTED
});

function getCalorieLevelsByPace(isProgramStartPage = false) {
  return dispatch => {
    const fulfilled = response =>
      dispatch(getCalorieLevelsByPaceFulfilled(response));
    const rejected = error => {
      dispatch(getCalorieLevelsByPaceRejected());
      dispatch(errorActions.errorHandler(error, isProgramStartPage));
    };

    dispatch(getCalorieLevelsByPacePending());

    return getCalorieLevelsByPaceFromApi(fulfilled, rejected, getUserId());
  };
}

function getCalorieLevelsByPaceNoProgram(params, isProgramStartPage = false) {
  return dispatch => {
    const fulfilled = response =>
      dispatch(getCalorieLevelsByPaceFulfilled(response));
    const rejected = error => {
      dispatch(getCalorieLevelsByPaceRejected());
      dispatch(errorActions.errorHandler(error, isProgramStartPage));
    };

    dispatch(getCalorieLevelsByPacePending());

    return getCalorieLevelsByPaceNoProgramFromApi(fulfilled, rejected, params);
  };
}

const getCalorieLevelsByPaceForPartialGoalPending = () => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_PENDING
});

const getCalorieLevelsByPaceForPartialGoalFulfilled = response => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_FULFILLED,
  response
});

const getCalorieLevelsByPaceForPartialGoalRejected = () => ({
  type: types.GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_REJECTED
});

function getCalorieLevelsByPaceForPartialGoal(
  props,
  isProgramStartPage = false
) {
  return dispatch => {
    const fulfilled = response =>
      dispatch(getCalorieLevelsByPaceForPartialGoalFulfilled(response));
    const rejected = error => {
      dispatch(getCalorieLevelsByPaceForPartialGoalRejected());
      dispatch(errorActions.errorHandler(error, isProgramStartPage));
    };

    dispatch(getCalorieLevelsByPaceForPartialGoalPending());

    return getCalorieLevelsByPaceNoProgramFromApi(fulfilled, rejected, props);
  };
}

const postCalorieLevelsByPacePending = () => ({
  type: types.POST_CALORIE_LEVELS_BY_PACE_PENDING
});

const postCalorieLevelsByPaceFulfilled = response => ({
  type: types.POST_CALORIE_LEVELS_BY_PACE_FULFILLED,
  response
});

const postCalorieLevelsByPaceRejected = () => ({
  type: types.POST_CALORIE_LEVELS_BY_PACE_REJECTED
});

function postCalorieLevelsByPace(payload) {
  return dispatch => {
    const fulfilled = response =>
      dispatch(postCalorieLevelsByPaceFulfilled(response));

    const rejected = error => {
      dispatch(postCalorieLevelsByPaceRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postCalorieLevelsByPacePending());

    return postCalorieLevelsByPaceToApi(
      fulfilled,
      rejected,
      payload,
      getUserId()
    );
  };
}

function postCalorieLevelsByPaceAndUpdateView(payload) {
  return dispatch =>
    dispatch(postCalorieLevelsByPace(payload)).then(() => {
      updateProgramView(dispatch);
      dispatch(modalsActions.hideModal(modals.EDIT_KCAL_MODAL_ID));
    });
}

function updateProgramView(dispatch) {
  dispatch(weightplanActions.getWeightplan());
  dispatch(partialGoalActions.getPartialGoal());
}

const setCurrentPaceIndex = value => ({
  type: types.SET_CURRENT_PACE_INDEX,
  value
});

export default {
  getCalorieLevelsByPace,
  getCalorieLevelsByPaceNoProgram,
  getCalorieLevelsByPaceForPartialGoal,
  postCalorieLevelsByPaceAndUpdateView,
  setCurrentPaceIndex
};
