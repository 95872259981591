import { updatePurchaseLinks } from './utils';

import {
  heroMobile,
  heroDesktop,
  heroTablet,
  heroMobileCampaign,
  heroTabletCampaign,
  heroDesktopCampaign,
  additionalOfferCampaign,
  campaignsConfig
} from './assets/wellobe';

const getAssets = () => ({
  heroDesktop,
  heroMobile,
  heroTablet,
  heroDesktopCampaign,
  heroTabletCampaign,
  heroMobileCampaign,
  additionalOfferCampaign,
  campaignsConfig
});

const updateEnvVariables = activeConfig => {
  const clientId = '4e8465d59caf7c150d000001';
  const codePrefix = 'WELLOBE-';
  const codeSuffix = 'MT-T';
  const publisher = 'vkse';
  return updatePurchaseLinks(
    activeConfig,
    clientId,
    codePrefix,
    codeSuffix,
    publisher
  );
};

export default { getAssets, updateEnvVariables };
