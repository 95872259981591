import findIndex from 'lodash/findIndex';

const getRoundedPacePerWeekInKilos = pacePerWeekInKilos => {
  const pacePerWeek = pacePerWeekInKilos ? pacePerWeekInKilos.toString() : '';
  const decimals = pacePerWeek.substr(pacePerWeek.indexOf('.') + 1);

  return decimals.length > 2
    ? parseFloat(pacePerWeekInKilos.toFixed(2))
    : pacePerWeekInKilos;
};

const defaultCurrentPaceIndex = (levels, pacePerWeekInKilos) => {
  const roundedPacePerWeekInKilos =
    getRoundedPacePerWeekInKilos(pacePerWeekInKilos);
  const match = findIndex(
    levels,
    level => level.pacePerWeek === roundedPacePerWeekInKilos
  );

  return match !== -1
    ? match
    : findIndex(levels, level => level.recommendedInitialPace);
};

const getCurrentPaceIndex = (currentPaceIndex, levels, pacePerWeekInKilos) => {
  if (currentPaceIndex > -1) {
    return currentPaceIndex;
  }

  return defaultCurrentPaceIndex(levels, pacePerWeekInKilos);
};

const getCurrentLevel = (currentPaceIndex, levels, pacePerWeekInKilos) => {
  const currentIndex = getCurrentPaceIndex(
    currentPaceIndex,
    levels,
    pacePerWeekInKilos
  );

  const level = levels && levels.length ? levels[currentIndex] : {};

  return {
    currentPaceIndex: currentIndex,
    content: {
      weeks: level.weeks,
      kcal: level.kcal,
      pacePerWeek: level.pacePerWeek,
      targetDate: level.targetDate
    },
    payload: {
      pacePerWeek: level.pacePerWeek,
      kcal: level.kcal
    }
  };
};

const getRangeMax = levels => levels.length - 1;

export default {
  getCurrentLevel,
  getRangeMax,
  defaultCurrentPaceIndex,
  getCurrentPaceIndex,
  getRoundedPacePerWeekInKilos
};
