import { updatePurchaseLinks } from './utils';

import {
  heroDesktop,
  heroMobile,
  heroTablet,
  heroDesktopCampaign,
  heroTabletCampaign,
  heroMobileCampaign,
  additionalOfferCampaign,
  campaignsConfig
} from './assets/vektklubb';

const getAssets = () => ({
  heroDesktop,
  heroMobile,
  heroTablet,
  heroDesktopCampaign,
  heroTabletCampaign,
  heroMobileCampaign,
  additionalOfferCampaign,
  campaignsConfig
});

const updateEnvVariables = activeConfig => {
  const clientId = '5c07ca44115e4909118b6d22';
  const codePrefix = 'VEK-';
  const codeSuffix = 'MT-E';
  const publisher = 'vkno';
  return updatePurchaseLinks(
    activeConfig,
    clientId,
    codePrefix,
    codeSuffix,
    publisher
  );
};

export default { getAssets, updateEnvVariables };
