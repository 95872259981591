/* eslint-disable no-param-reassign */
/* eslint-disable max-depth */
import types from './types';
import initialState from './initialState';

const articleSectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ARTICLE_SECTION_FULFILLED: {
      const currentListIndex = state.lists.findIndex(
        ({ articleListId }) => articleListId === action.id
      );
      if (currentListIndex > -1) {
        if (action.articleSearchPage === 0) {
          state.lists[currentListIndex].items = action.response;
        } else if (state.articleSearchPage !== action.articleSearchPage) {
          state.lists[currentListIndex].items.push(...action.response);
        }
        return {
          ...state,
          error: undefined,
          articleSearchPage: action.articleSearchPage,
          salesposters: action.salesposters,
          pending: false,
          fulfilled: true
        };
      }
      return {
        ...state,
        error: undefined,
        lists: [
          ...state.lists,
          {
            articleListId: action.id,
            items: [...action.response]
          }
        ],
        articleSearchPage: action.articleSearchPage,
        salesposters: action.salesposters,
        pending: false,
        fulfilled: true
      };
    }
    case types.CREATE_ARTICLE_SECTION_PENDING: {
      return {
        ...state,
        error: undefined,
        pending: true,
        fulfilled: false
      };
    }
    case types.CREATE_ARTICLE_SECTION_REJECTED: {
      return {
        ...state,
        pending: false,
        error: {
          message: action.error.message
        }
      };
    }
    case types.CLEAR_ARTICLE_SECTIONS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default articleSectionsReducer;
