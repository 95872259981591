/* eslint-disable no-param-reassign */
/* eslint-disable max-depth */
import types from './types';
import initialState from './initialState';

const articlesSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ARTICLES_SEARCH_FULFILLED: {
      return {
        ...state,
        error: undefined,
        list: [...action.response],
        pending: false,
        fulfilled: true
      };
    }
    case types.CREATE_ARTICLES_SEARCH_PENDING: {
      return {
        ...state,
        error: undefined,
        pending: true,
        fulfilled: false
      };
    }
    case types.CREATE_ARTICLES_SEARCH_REJECTED: {
      return {
        ...state,
        pending: false,
        error: {
          message: action.error.message
        }
      };
    }
    case types.CLEAR_ARTICLES_SEARCH: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default articlesSearchReducer;
