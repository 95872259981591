import types from './types';

const articlesSearchFulfilled = (response, searchPhrase) => ({
  type: types.CREATE_ARTICLES_SEARCH_FULFILLED,
  response,
  searchPhrase
});

const articlesSearchRejected = error => ({
  type: types.CREATE_ARTICLES_SEARCH_REJECTED,
  error: {
    message: error.message
  }
});

const articlesSearchPending = () => ({
  type: types.CREATE_ARTICLES_SEARCH_PENDING
});

function getSalesArticlesSearchList(searchPhrase) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(articlesSearchFulfilled(response, searchPhrase));
    };

    const rejected = error => dispatch(articlesSearchRejected(error));

    const search = searchPhrase ? `&q=${searchPhrase}` : '';

    const params = `?size=32${search}`;

    dispatch(articlesSearchPending());

    return wsCalls().getArticleCollection(fulfilled, rejected, params);
  };
}

function getPaywallArticlesSearchList(
  id,
  articleSearchPage,
  articlesNumber = 15,
  tags
) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(articlesSearchFulfilled(response, id));
    };

    const rejected = error => dispatch(articlesSearchRejected(error));
    const size = articlesNumber ? `?size=${articlesNumber}` : '';
    const searchPage = articleSearchPage ? `&pageId=${articleSearchPage}` : '';
    const tagsFilter = tags ? `&tags=${tags}` : '';

    const params = `${size}${searchPage}${tagsFilter}`;

    dispatch(articlesSearchPending());

    return wsCalls().getArticleCollection(fulfilled, rejected, params);
  };
}

const clearArticleSections = () => ({ type: types.CLEAR_ARTICLE_SECTIONS });

export default {
  getSalesArticlesSearchList,
  getPaywallArticlesSearchList,
  clearArticleSections
};
