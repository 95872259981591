export default {
  GET_CALORIE_LEVELS_BY_PACE_PENDING:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_PENDING',
  GET_CALORIE_LEVELS_BY_PACE_FULFILLED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_FULFILLED',
  GET_CALORIE_LEVELS_BY_PACE_REJECTED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_REJECTED',
  GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_PENDING:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_PENDING',
  GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_FULFILLED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_FULFILLED',
  GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_REJECTED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_PARTIAL_GOAL_REJECTED',
  GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_PENDING:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_PENDING',
  GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_FULFILLED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_FULFILLED',
  GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_REJECTED:
    'calorieLevelsByPace/GET_CALORIE_LEVELS_BY_PACE_NO_PROGRAM_REJECTED',
  POST_CALORIE_LEVELS_BY_PACE_PENDING:
    'calorieLevelsByPace/POST_CALORIE_LEVELS_BY_PACE_PENDING',
  POST_CALORIE_LEVELS_BY_PACE_FULFILLED:
    'calorieLevelsByPace/POST_CALORIE_LEVELS_BY_PACE_FULFILLED',
  POST_CALORIE_LEVELS_BY_PACE_REJECTED:
    'calorieLevelsByPace/POST_CALORIE_LEVELS_BY_PACE_REJECTED',
  SET_CURRENT_PACE_INDEX: 'calorieLevelsByPace/SET_CURRENT_PACE_INDEX'
};
