import requestStatus from '@src/utils/helpers/requestStatus';

export default {
  id: '',
  title: '',
  lead: undefined,
  error: undefined,
  topImage: undefined,
  bodyContent: undefined,
  authors: undefined,
  categories: undefined,
  tags: undefined,
  topEmbed: undefined,
  published: undefined,
  updated: undefined,
  topVideo: undefined,
  canonicalUrl: undefined,
  metaData: {
    metaDescription: undefined,
    metaTitle: undefined,
    ogDescription: undefined,
    ogTitle: undefined,
    socialMediaImage: undefined
  },
  salesposters: undefined,
  requestStatus: requestStatus.pending,
  noindex: false
};
