import moment from 'moment';
import getMoment from '@src/utils/helpers/campaign';
import config from '../../config';

const shouldForceProductionConfig = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('campaignEnvironment') === 'pro';
};

export const updatePurchaseLinks = (
  activeConfig,
  clientId,
  codePrefix,
  codeSuffix,
  publisher
) => {
  const isProduction = config.schibstedAccount.env !== 'PRE';
  if (!isProduction && !shouldForceProductionConfig()) {
    const modifiedConfig = activeConfig;
    const {
      schibstedAccount: { redirectUri }
    } = config;
    modifiedConfig.buySubscription.boxes.forEach((box, index) => {
      const code = `${codePrefix}${box.offerLength}${codeSuffix}`;
      modifiedConfig.buySubscription.boxes[
        index
      ].url = `https://pay.pre.schibsted.digital/authn/payment/purchase/code?client_id=${clientId}&code=${code}&publisher=${publisher}&redirect_uri=${redirectUri}`;
    });
    return modifiedConfig;
  }
  return activeConfig;
};

export const getActiveCampaign = ({ campaigns }) =>
  campaigns.filter(campaign => {
    const {
      campaignName,
      campaignIsActive,
      campaignEndDate,
      campaignStartDate
    } = campaign;
    if (campaignName === 'default') return true;
    if (!campaignIsActive) return false;
    const endCampaignMoment = moment(campaignEndDate);
    const startCampaignMoment = moment(campaignStartDate);
    const m = getMoment();
    return (
      m.isSame(startCampaignMoment, endCampaignMoment) ||
      m.isBetween(startCampaignMoment, endCampaignMoment)
    );
  })[0];
